import React from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Link from '../components/Link';

import Imgix from 'react-imgix';
import Layout from '../components/Layout';
import SearchEngineOptimization from '../components/SearchEngineOptimization';

import { unified } from "unified"
import parse from "rehype-parse"
import rehypeReact from "rehype-react"
import { Button } from '@material-ui/core';
import PostCard from "../components/PostCard"
const moment = require("moment")

const useStyles = makeStyles((theme) => ({
  hero: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    width: `100%`
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 5,
  },
  heroFigure: {
    maxHeight: "100%",
    height: "250px",
    margin: 0,
    zIndex: 1,
  },
  heroFigureImg: {
    width: "100%",
    height: "250px",
    objectFit: "cover",
  },
  heroCatch: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: "auto auto 10%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    color: "#fff",
    textShadow: "0 0 20px rgba(0,0,0,0.5)",
    zIndex: 1000,
  },
  blockTitle: {
    marginTop: `1rem`
  }
}))

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: {
  },
}).Compiler

const Index = (({ data }) => {
  const classes = useStyles()
  const htmlAst = unified()
    .use(parse, { fragment: true })
    .parse(data.allMicrocmsSite.edges[0].node.desc)

  const _now = new Date();
  const buildTimeISO = moment(_now).toISOString()
  console.log(`page/index.js:★buildTimeISO★: ${buildTimeISO}`)

  const _commingSchedules = data.schedulesPosts.edges.filter((edge) => edge.node.fields.scheduledAt >= buildTimeISO)
  const commingSchedules = [];
  for (let i = 0; i < _commingSchedules.length; i++) {
    commingSchedules.push(_commingSchedules[i])
    if (commingSchedules.length >= 3) {
      break
    }
  }

  return (
    <Layout
      siteMetadata={data.allMicrocmsSite.edges[0].node}
      categories={data.allMicrocmsCategory.edges}
      hideCategory={true}>
      <SearchEngineOptimization />
      <Paper className={classes.hero}>
        <div className={classes.overlay} />
        <figure className={classes.heroFigure}>
          <Imgix className={classes.heroFigureImg}
            src={data.allMicrocmsSite.edges[0].node.hero.url}
            htmlAttributes={{
              alt: `${data.allMicrocmsSite.edges[0].node.title} main image`
            }}
          />
        </figure>
      </Paper>

      <Typography className={classes.blockTitle} component="h6" variant="h6">新着記事/Recent Posts:</Typography>

      <Grid container spacing={4}>
        {
          data.recentPosts.edges.map((edge) => (
            <PostCard edge={edge} categorySlug={`blog`} key={edge.node.id} />
          ))
        }
        {data.recentPosts.edges.length <= 0 &&
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">準備中です。</Typography>
          </Grid>
        }
      </Grid>

      <Typography className={classes.blockTitle} component="h6" variant="h6">カテゴリー/Categories:</Typography>

      <Grid container>
        {
          data.allMicrocmsCategory.edges.map((edge) => (
            <Grid item lg={4} md={6} sm={12} className={classes.hero} key={edge.node.categorySlug}>
              <Paper>
                <div className={classes.overlay} />
                <figure className={classes.heroFigure}>
                  <Imgix className={classes.heroFigureImg}
                    src={edge.node.categoryHero.url}
                    htmlAttributes={{
                      alt: `カテゴリー「${edge.node.categoryTitle}」 メイン画像`
                    }}
                  />
                </figure>
                <div className={classes.heroCatch}>
                  <Typography component="h2" variant="h3" color="inherit" gutterBottom>
                    {edge.node.categoryTitle}
                  </Typography>
                  <Link variant="subtitle1" to={`/${edge.node.categorySlug}`}>
                    <Button color="primary" variant="contained">{`READ MORE`}</Button>
                  </Link>
                </div>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </Layout>
  )
})

export const query = graphql`
query {
  allMicrocmsCategory(sort: {fields: categoryNo, order: ASC}) {
    edges {
      node {
        categoryTitle
        categorySlug
        categoryHero {
          url
          height
          width
        }
        categoryNo
      }
    }
  }
  allMicrocmsSite {
    edges {
      node {
        id
        hero {
          url
          height
          width
        }
        title
        desc
        siteUrl
        lang
        locale
      }
    }
  }
  schedulesPosts: allMicrocmsBlog(
    filter: {category: {elemMatch: {categorySlug: {in: ["schedules"]}}}, fields: {hasScheduledAt: {eq: true}}}
    sort: {fields: fields___scheduledAt}
  ) {
    edges {
      node {
        id
        title
        slug
        publishedAt
        fields {
          hasScheduledAt
          scheduledAt
          blogDescription
        }
        eyecatch {
          url
          height
          width
        }
      }
    }
  }
  recentPosts: allMicrocmsBlog(
    limit: 3
    sort: {fields: publishedAt, order: DESC}
  ) {
    edges {
      node {
        id
        title
        slug
        publishedAt
        fields {
          hasScheduledAt
          scheduledAt
          blogDescription
        }
        eyecatch {
          url
          height
          width
        }
      }
    }
  }
}
`

export default Index